import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OfficeImages.css";
import homeData from "../../jsonData/TotalData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const OfficeImages = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: (
      <button className="slick-prev" type="button">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    ),
    nextArrow: (
      <button className="slick-next" type="button">
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          prevArrow: (
            <button className="slick-prev" type="button">
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          ),
          nextArrow: (
            <button className="slick-next" type="button">
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          ),
        },
      },
    ],
  };

  const data = homeData[10];

  return (
    <div className="OfficeImage-slider">
      <Slider {...settings}>
        {data.OfficeImages.map((imageData, index) => (
          <div key={index} className="OfficeImage-container">
            <div className="image-wrapper">
              <img src={imageData.image} alt={`Office ${index + 1}`} className="OfficeImage-image"/>
              <div className="image-overlay">
                <div className="image-description">{imageData.description}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OfficeImages;
