import React from 'react';
import './Projects.css';
import projects from "../../jsonData/Projects.json";
import totalData from '../../jsonData/TotalData.json';
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import ProjectCardRow from '../../component/ProjectCardRow/ProjectCardRow';



const Projects = () => {
  const data = totalData[7];
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <ProjectCardRow data={projects}/>
    </div>
  )
}

export default Projects;
