import React from 'react';
import './AllAboutSection.css';

const AllAboutSection = ({heading1 ,heading2 ,description ,image}) => {
  return (
    <div className="AboutUs-container">
      <div className="AboutUs-left">
        <h1 className="AboutUS-text">{heading1}</h1>
        <h1 className="AboutUs-heading">{heading2}</h1>
        <p className="Paragraph-style">
          {description}
        </p>
      </div>
      <div className="about-shape">
        <img  className="office-image" src={image} alt="thisimage"></img>
      </div>
    </div>
  )
}

export default AllAboutSection;
