import React from 'react';
import { useParams } from 'react-router-dom';
import './CapabilitiesPage.css';
import services from "../../jsonData/Services.json";
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../component/AllAboutSection/AllAboutSection';
import AllTestimonial from '../../component/AllTestimonial/AllTestimonial';
import AllProjects from '../../component/AllProjects/AllProjects';
import AllContactUs from '../../component/AllContactUs/AllContactUs';
import CapabilitiesDetails from '../../component/CapabilitiesDetails/CapabilitiesDetails';
import projects from "../../jsonData/Projects.json";
import totalData from "../../jsonData/TotalData.json";


const CapabilitiesPage = () => {
  const { id } = useParams();
  const data = services.find(page => page.id === parseInt(id));

  if (!data) {  // Corrected from "date" to "News"
    return <div>Page not found</div>;
  }


  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection heading1={data.title} heading2={data.AboutUsTitle} description={data.AboutUsDescription} image={data.AboutUsImage} />
      <CapabilitiesDetails data={data}/>
      <AllTestimonial/>
      <AllProjects data={projects} details={totalData[3]}/>
      <AllContactUs/>
    </div>
  )
}

export default CapabilitiesPage;
