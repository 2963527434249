import React from 'react';
import './AboutPrinciplesList.css';

const AboutPrinciplesList = ({data,para}) => {

  return (
    <div className="principles-container">
        <p className='principles-Para'>{para}</p>
      {data.map((principle, index) => (
        <div key={index} className="principle-item">
          <div className="principle-number">{principle.number}</div>
          <div className="principle-content">
            <h2 className="principle-title">{principle.title}</h2>
            <p className="principle-description">{principle.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutPrinciplesList;
