import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Capabilities from './pages/Capabilities/Capabilities';
import Career from './pages/Career/Career';
import Projects from './pages/Projects/Projects'; 
import About from './pages/About/About';
import ProjectPage from './pages/ProjectPage/ProjectPage'; 
import CapabilitiesPage from './pages/CapabilitiesPage/CapabilitiesPage'; 
import NavBar from './pages/NavBar/NavBar';
import Footer from './pages/Footer/Footer';
import Contact from './pages/Contact/Contact';
import NotFound from './pages/NotFound/NotFound';
import NewsRoom from './pages/NewsRoom/NewsRoom';
import NewsRoomPage from "./pages/NewsRoomPage/NewsRoomPage";

const AppRoutes = () => {
  return (
    <Router>
      <div>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/capabilities" element={<Capabilities />} /> 
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectPage />} /> 
          <Route path="/capabilities/:id" element={<CapabilitiesPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/news" element={<NewsRoom/>} />
          <Route path="/news/:id" element={<NewsRoomPage/>} />
          <Route path='*' element={<NotFound />} /> 
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};

export default AppRoutes;