import React from 'react';
import industries from "../../jsonData/Industries.json";
import services from "../../jsonData/Services.json";
import news from "../../jsonData/News.json";
import projects from "../../jsonData/Projects.json";
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import './AllLinkPages.css';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const AllLinkPages = () => {
  const { id } = useParams();
  const location = useLocation();

  // Determine the category based on the current path
  const category = location.pathname.includes('/industries') ? 'industries' :
                   location.pathname.includes('/capabilities') ? 'capabilities' :
                   location.pathname.includes('/news') ? 'news' :
                   location.pathname.includes('/projects') ? 'projects' :
                   location.pathname.includes('/about') ? 'about' : 
                   location.pathname.includes('/sustainability') ? 'sustainability' :
                   location.pathname.includes('/career') ? 'career' :
                   location.pathname.includes('/contact') ? 'contact' : '';

  let item;
  if (id) {
    // Fetch the item based on the category
    switch (category) {
      case 'industries':
        item = industries.find(item => item.id === parseInt(id));
        break;
      case 'capabilities':
        item = services.find(item => item.id === parseInt(id));
        break;
      case 'news':
        item = news.find(item => item.id === parseInt(id));
        break;
      case 'projects':
        item = projects.find(item => item.id === parseInt(id));
        break;
      default:
        break;
    }
  }

  return (
    <div className='AllLinkPage-container'>
      <p className='AllLinkPage-para'>
        <Link to="/">Home</Link> |
        <Link to={`/${category}`} className={location.pathname === `/${category}` ? 'active' : ''}>
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </Link> 
        {item && (
          <span> |  
            <Link to={`/${category}/${id}`} className={location.pathname === `/${category}/${id}` ? 'active' : ''}>
              {item.title}
            </Link>
          </span>
        )}
      </p>
    </div>
  );
}

export default AllLinkPages;
