import React, { useState, useEffect } from 'react';
import './HeroIntro.css';
import Button from "../Button/Button";

const HeroIntro = ({ data }) => {
  const [currentParaIndex, setCurrentParaIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentParaIndex((prevIndex) => (prevIndex + 1) % data.HeroPara.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [data.HeroPara.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set the initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile) {
    return <HeroIntroMobile data={data} currentParaIndex={currentParaIndex} />;
  }

  return (
    <div className='HeroIntro-Container'>
      <video className='HeroIntro-BackgroundVideoweb' autoPlay loop muted>
        <source src='/images/video.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div className='HeroIntro-sections'>
        <div className='HeroIntro-LeftSection'>
          <img src={data.HeroImage} alt='' className='HeroIntro-ImageLeft' />
        </div>
        <div className='HeroIntro-RightSection'>
          <h1 className='HeroIntro-RightHeading'>{data.HeroHeading}</h1>
          <p className='HeroIntro-RightPara'>ONE THAT {data.HeroPara[currentParaIndex].PARA.toUpperCase()}</p>
          <Button to="/about">{data.HeroButton}</Button>
        </div>
      </div>
    </div>
  );
};

const HeroIntroMobile = ({ data, currentParaIndex }) => {
  return (
    <div className='HeroInto-MainContainer-Mobile'>
      <video className='HeroIntro-BackgroundVideo' autoPlay loop muted>
        <source src='/images/video.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <img src={data.HeroImage} alt='Overlay' className='HeroIntro-OverlayImage' />
      <div className='HeroIntro-Container-Mobile'>
        <div className='HeroIntro-sections-Mobile'>
          <h1 className='HeroIntro-RightHeading-Mobile'>{data.HeroHeading}</h1>
          <p className='HeroIntro-RightPara-Mobile'>ONE THAT {data.HeroPara[currentParaIndex].PARA.toUpperCase()}</p>
          <Button to="/about">{data.HeroButton}</Button>
        </div>
      </div>
    </div>
  );
};

export default HeroIntro;
