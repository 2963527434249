import React from 'react';
import './Capabilities.css';
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import totalData from '../../jsonData/TotalData.json';
import services from "../../jsonData/Services.json";
import AllCapabilities from '../../component/AllCapabilities/AllCapabilities';

const Capabilities = () => {
  const data = totalData[5];
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllCapabilities title={data.capabilitiesTitles} description={data.capabilitiesDescription} capabilities={services} CurrentPage={data.LinkCapabilities}/>
    </div>
  )
}

export default Capabilities;
