import servicesData from "../../jsonData/Services.json"
// import projectsData from "../../jsonData/Projects.json"
// import industriesData from "../../jsonData/Industries.json"



export const links = [
    {
      name: "Capabilities",
      submenu: true,
      link: "/capabilities",
      sublinks: servicesData.map((item) => ({
        title: item.title,
        id: item.id,
      })),
    }
    // {
    //   name: "Projects",
    //   submenu: true,
    //   link: "/projects",
    //   sublinks: projectsData.map((item) => ({
    //     title: item.title,
    //     id: item.id,
    //   })),
    // },
  ];