import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import LogoSlider1 from '../../Assets/LogoSlider1.svg';
import LogoSlider2 from '../../Assets/logoSlider2.png';
import LogoSlider3 from "../../Assets/logoSlider3.png";
import LogoSlider4 from "../../Assets/logoSlider4.png";
import LogoSlider8 from '../../Assets/LogoSlider8.svg';
import LogoSlider11 from '../../Assets/LogoSlider11.svg';
import LogoSlider17 from "../../Assets/LogoSlider17.svg";

const slides = [
    { icon: <img src={LogoSlider1} alt="Logo 1" style={{ width: "100px", height: "100px" }} /> },
    { icon: <img src={LogoSlider2} alt="Logo 2" style={{ width: "100px", height: "100px" }} /> },
    { icon: <img src={LogoSlider3} alt="Logo 3" style={{ width: "100px", height: "100px" }} /> },
    { icon: <img src={LogoSlider4} alt="Logo 4" style={{ width: "100px", height: "100px" }} /> },
    { icon: <img src={LogoSlider8} alt="Logo 8" style={{ width: "100px", height: "100px" }} /> },
    { icon: <img src={LogoSlider11} alt="Logo 11" style={{ width: "100px", height: "100px" }} /> },
    { icon: <img src={LogoSlider17} alt="Logo 17" style={{ width: "100px", height: "100px" }} /> },
];

const HomeIconSlider = () => {
    const duplicatedSlides = [...slides, ...slides];

    const animationControls = useAnimation();
    const [ref, inView] = useInView();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (inView) {
            animationControls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 1 },
            });
        }
    }, [animationControls, inView]);

    return windowWidth >= 680 ? (
        <motion.div
            className="animated-section"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={animationControls}
        >
            <div className="relative h-full overflow-hidden py-12 bg-white mx-auto" style={{ width: "70%" }}>
                <div className="absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-white before:to-transparent after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-white after:to-transparent"></div>

                <motion.div
                    className="flex"
                    animate={{
                        x: ['0%', '-100%'],
                        transition: {
                            ease: 'linear',
                            duration: 15,
                            repeat: Infinity,
                        }
                    }}
                >
                    {duplicatedSlides.map((slide, index) => (
                        <div key={index} className="flex-shrink-0" style={{ width: `${100 / slides.length}%` }}>
                            <div className="flex items-center justify-center h-full mr-8">
                                {slide.icon}
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>
        </motion.div>
    ) : null;
};

export default HomeIconSlider;
