import React from 'react';
import news from "../../jsonData/News.json";
// import TotalData from "../../jsonData/TotalData.json";
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import { useParams } from 'react-router-dom';
import NewsRoomContent from '../../component/NewsRoomContent/NewsRoomContent';

const NewsRoomPage = () => {
  const { id } = useParams();
  const News = news.find(page => page.id === parseInt(id));

  if (!News) {  // Corrected from "date" to "News"
    return <div>Page not found</div>;
  }

  // const data = TotalData[3];

  return (
    <div>
       <AllIntroduction title={News.title} description={News.description} image={News.image}/>
       <NewsRoomContent details={News.details} date={News.date}/>

    </div>
   
  )
}

export default NewsRoomPage