import React from 'react';
import './Career.css';
import totalData from '../../jsonData/TotalData.json';
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../component/AllAboutSection/AllAboutSection';
import CareerHiring from '../../component/CareerHiring/CareerHiring';
import CareersJobs from '../../component/CareersJobs/CareersJobs';
import OfficeImages from '../../component/OfficeImages/OfficeImages';


const Career = () => {
  const data = totalData[10];
  return (
    <div>
       <AllIntroduction title={data.title} description={data.description} image={data.image}/>
       <AllAboutSection heading1={data.AboutUsHeading} heading2={data.AboutUsTitle} description={data.AboutUsDescription} image={data.AboutUsImage} />
       <OfficeImages/>
       <CareerHiring cards={data.CareerHiring} />
       <CareersJobs/>
    </div>
  )
}

export default Career;
