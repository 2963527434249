import React, { useRef, useEffect } from 'react';
import TestimonialCard from './TestimonialCard'; // Import the TestimonialCard component
import './AllTestimonial.css'; // Import the CSS file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import TotalData from "../../jsonData/TotalData.json";

const AllTestimonial = () => {
  const sliderRef = useRef(null);
  const data = TotalData[3];

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='testimonial-container'>
      <h1 className="testimonial-title">{data.testimonialHeader}</h1>
      <p className="testimonial-para">{data.testimonialPara}</p>
      <div className="testimonial-carousel-wrapper">
        <div className="testimonial-carousel-container">
          <Slider ref={sliderRef} {...settings}>
            {data.testimonial.map((testimonial, index) => (
              <div key={index}>
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AllTestimonial;
