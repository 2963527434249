import React, { useEffect } from 'react';
import './AllProjects.css'; // Import the CSS file
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Projects from "../../jsonData/Projects.json";
import totalData from "../../jsonData/TotalData.json";

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} className='LinkServices' {...rest}>
      {children}
    </RouterLink>
  );
};

function AllProjects() {
  const animationControls = useAnimation();
  const [ref, inView] = useInView();
  const data = Projects;
  const details= totalData[3];

  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  // Slice the data to show only the first 6 cards
  const visibleData = data.slice(0, 6);

  return (
    <div className="ServicesCat-container">
      <motion.div
        className="animated-section"
        ref={ref}
        initial={{ opacity: 0, y: 60 }}
        animate={animationControls}
      >
        <div className='headertop-services'>
          <div className='headertop-right'>
            <h1 className="ServicesCat-title">{details.ServicesCatTitle + "_"}</h1>
          </div>

          <div className='headertop-left'>
            <p className="ServicesCat-description">{details.ServicesCatDescription}</p>
            <Link to={details.ServicesLink} className='LinkServices'> 
              {details.ServicesCatLinkName} 
              <FontAwesomeIcon icon={faArrowRight} className='iconServices'/>
            </Link>
          </div>
        </div>

        <div className='card-servicesall'>
          {visibleData.map((item) => (
            <div key={item.id} className="cardservices-container">
              <Link to={`/projects/${item.id}`}>
                <img src={item.image} alt='' className='cardservices-image'/>
                <h1 className='cardservices-title'>{item.title}</h1>
                <div className="cardservices-overlay">
                  <p className='cardservices-description'>{item.description}</p>
                  <Link to={`/services/${item.id}`} className='LinkServices2'>
                    Further Information
                    <FontAwesomeIcon icon={faArrowRight} className='iconServices'/>
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default AllProjects;
