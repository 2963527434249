import React from "react";
import "./AllValues.css";

const ValueGrid = ({facts}) => {
  return (
    <div className="Value-GridContainer">
      <grid className="Value-GridBlock">
        {facts.map((fact, index) => (
          <div key={index} className="Value-Grid">
            <div className="Value-GridTitle">{fact.title}</div>

            <div className="Value-GridDescription">{fact.description}</div>
          </div>
        ))}
      </grid>
    </div>
  );
};

const AllValues = ({data}) => {
  return (
    <div className="Value-Section">
      <div className="Value-Title">{data.ValuesTitle}</div>
      <div>
        <ValueGrid facts={data.facts}/>
      </div>
    </div>
  );
};

export default AllValues;
