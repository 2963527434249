import React from 'react';
import { useParams } from 'react-router-dom';
import './ProjectPage.css';
import projects from "../../jsonData/Projects.json";
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import ProjectsDetails from '../../component/ProjectsDetails/ProjectsDetails';
import AllProjects from '../../component/AllProjects/AllProjects';
import AllContactUs from '../../component/AllContactUs/AllContactUs';




const ProjectPage = () => {
  const { id } = useParams();
  const data = projects.find(page => page.id === parseInt(id));

  if (!data) {  // Corrected from "date" to "News"
    return <div>Page not found</div>;
  }


  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <ProjectsDetails location={data.location} cost={data.cost} description={data.detailsAbout} images={data.photo}/>
      <AllProjects/>
      <AllContactUs/>
    </div>
  )
}

export default ProjectPage;
