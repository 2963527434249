import React from 'react';
import totalData from '../../jsonData/TotalData.json';
import News from '../../jsonData/News.json';
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import NewsRoomList from '../../component/NewsRoomList/NewsRoomList';


const NewsRoom = () => {
  const data = totalData[11];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <NewsRoomList data={News}/>
    </div>
    
  )
}
export default NewsRoom