import React from 'react';
import './CareersJobs.css'; 
import emailjs from 'emailjs-com';
import data from "../../jsonData/TotalData.json";

function CareersJobs() {
    const sendEmail = (e) => {
        e.preventDefault();
    
        const { name, email, phone } = e.target.elements; // eslint-disable-line
    
        if (!/^\d{10}$/.test(phone.value)) {
            alert("Phone number must be exactly 10 digits.");
            return;
        }
    
        emailjs.sendForm('service_ystlmqj', 'template_pwxep1n', e.target, 'efnQ_-lOOJQ0YcFtY')
          .then((result) => {
            console.log(result.text);
            alert("Form submitted successfully!");
          }, (error) => {
            console.log(error.text);
            alert("There was an error submitting the form. Please try again.");
          });
    
        e.target.reset();
    };

    const TotalData = data[2];
    console.log(TotalData);

    return (
        <div className='Careers_jobs-container'>
            <div className='Careers_jobs-contain'>
                <h1 className="Careers_jobs-title">Get In Touch</h1>
                
                <form onSubmit={sendEmail}>
                    <div className="Careers_jobs-group">
                        <input type="text" id="fullname" name="fullname" placeholder='Full Name' required />
                    </div>
                    <div className="Careers_jobs-group">
                        <input type="text" id="lastname" name="lastname" placeholder='Last Name' required />
                    </div>
                    <div className="Careers_jobs-group">
                        <input type="email" id="email" name="email" placeholder='Email' required />
                    </div>
                    <div className="Careers_jobs-group">
                        <input type="text" id="company" name="company" placeholder='Company Name' required />
                    </div>
                    <div className="Careers_jobs-group">
                        <textarea id="projectDetails" name="projectDetails" placeholder='Tell us about your project' required />
                    </div>
                    <div className="Careers_jobs-group">
                        <input type="tel" id="phone" name="phone" placeholder='Phone / Whatsapp' required />
                    </div>

                    <p className='Careers_jobs-privacy'>By submitting, I agree to Innotech's Privacy Policy.</p>

                    <div className="btnget-container">
                        <button type="submit" className="btn-Careers_jobs">LET'S TALK</button>
                    </div>
                </form>
            </div>
            <div className='careercontainer-card'>
                <h3>If need any info please contact <span className="us-highlight">us!</span></h3>
                <p className='heading-jobs'>Head office address:</p>
                <a href={TotalData.Location1} target="_blank" rel="noopener noreferrer">
                  <p className='Para-jobs'>{TotalData.Address1}</p>
                </a>
                <p className='heading-jobs'>Email: </p>
                <a href={`mailto:${TotalData.Email}`}>
                   <p className='Para-jobs'>{TotalData.Email}</p> 
                </a>
                <p className='heading-jobs'>Call for help: </p>
                <p className='Para-jobs'>+1234567</p>
                
                <div className="careercall-now-button">
                    <a href="https://wa.me/+917738899255/?text=Hi" target="_blank" rel="noopener noreferrer">
                        <button className="careercall-btn">Contact Now</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CareersJobs;
