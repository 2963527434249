import React from 'react';
import './Contact.css';
import totalData from '../../jsonData/TotalData.json';
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import CareersJobs from '../../component/CareersJobs/CareersJobs';
import ContactMap from '../../component/ContactMap/ContactMap';

const Contact = () => {
  const data = totalData[9];
  return (
    <div>
       <AllIntroduction title={data.title} description={data.description} image={data.image}/>
       <ContactMap data={data.ContactMap}/>
       <CareersJobs/>
    </div>
  )
}

export default Contact;
