import React from 'react';
import './TestimonialCard.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="testimonial-card">
      <div className="quote-section">
        <FontAwesomeIcon icon={faQuoteLeft} className='QuoteLeft'/>
        <p>{testimonial.description}</p>
        <FontAwesomeIcon icon={faQuoteRight} className='QuoteRight'/>
      </div>
      <section className="testimonial-section">
        <h3>{testimonial.name}</h3>
        <p>from {testimonial.location}</p>
        <p>at {testimonial.designation}</p>
        {/* <img src={testimonial.photo} alt={testimonial.name} className="testimonial-image" /> */}
      </section>
    </div>
  );
};

export default TestimonialCard;
