import React, { useState, useEffect } from 'react';
import "./CapabilitiesDetails.css";

const CapabilitiesDetails = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 786);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 786);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='CapabilitiesDetails-container'>
        <div className='CapabilitiesDetails-OverFlowContainer'>
            <h1 className='CapabilitiesDetails-OverFlowHeader'>
            {data.CapabilitiesDetailsHeader}
            </h1>
            <p className='CapabilitiesDetails-OverFlowPara'>
            {data.CapabilitiesDetailsPara}
            </p>
        </div>
      {data.CapabilitiesDetails.map((section, index) => (
        <React.Fragment key={index}>
          {isMobile ? (
            <div className='CapabilitiesDetails-SectionContainer'>
              <div className='CapabilitiesDetails-SectionLeft'>
                <img src={section.imageSrc} alt='' className='CapabilitiesDetails-imageLeft'/>
              </div>
              <div className='CapabilitiesDetails-SectionRight'>
                <h1 className='CapabilitiesDetails-SectionHeading'>{section.heading}</h1>
                <p className='CapabilitiesDetails-SectionPara'>{section.paragraph}</p>
              </div>
            </div>
          ) : section.type === 'CapabilitiesDetails-SectionContainer' ? (
            <div className='CapabilitiesDetails-SectionContainer'>
              <div className='CapabilitiesDetails-SectionLeft'>
                <img src={section.imageSrc} alt='' className='CapabilitiesDetails-imageLeft'/>
              </div>
              <div className='CapabilitiesDetails-SectionRight'>
                <h1 className='CapabilitiesDetails-SectionHeading'>{section.heading}</h1>
                <p className='CapabilitiesDetails-SectionPara'>{section.paragraph}</p>
              </div>
            </div>
          ) : section.type === 'CapabilitiesDetails-SectionContainer2' ? (
            <div className='CapabilitiesDetails-SectionContainer2'>
              <div className='CapabilitiesDetails-SectionLeft2'>
                <h1 className='CapabilitiesDetails-SectionHeading2'>{section.heading}</h1>
                <p className='CapabilitiesDetails-SectionPara2'>{section.paragraph}</p>
              </div>
              <div className='CapabilitiesDetails-SectionRight2'>
                <img src={section.imageSrc} alt='' className='CapabilitiesDetails-imageLeft2'/>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

export default CapabilitiesDetails;
