import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Example icon
import './Button1.css'; // Assuming you have a separate CSS file for styles
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const Button1 = ({ children, to, icon }) => {
  const iconToUse = icon || faArrowRight;

  return (
    <Link to={to} className="button-white">
      {children}
      <FontAwesomeIcon icon={iconToUse} className="icon-white" />
    </Link>
  );
};

export default Button1;
