import React from 'react';
import './About.css';
import totalData from '../../jsonData/TotalData.json';
import AllIntroduction from '../../component/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../component/AllAboutSection/AllAboutSection';
import AllTestimonial from '../../component/AllTestimonial/AllTestimonial';
import HomeIconSlider from '../../component/HomeIconSlider/HomeIconSlider';
import AllContactUs from '../../component/AllContactUs/AllContactUs';
import CompanyPdfViewer from '../../component/CompanyPdfViewer/CompanyPdfViewer';
import AllValues from '../../component/AllValues/AllValues';
import CompanyDirectors from '../../component/CompanyDirectors/CompanyDirectors';
import AboutPrinciplesList from '../../component/AboutPrinciplesList/AboutPrinciplesList';

const About = () => {
  const data = totalData[4];
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
       <AllAboutSection heading1={data.AboutHeading1} heading2={data.AboutHeading2} description={data.Aboutpara} image={data.AboutImage} />
       <AboutPrinciplesList para={data.PrinciplesPara} data={data.Principles}/>
       <CompanyPdfViewer data={data.PdfCompany} headingPdf={data.headingPdf}/>
       <AllValues data={data}/>
       <CompanyDirectors cards={data.companyStackHolder}/>
       <AllTestimonial/>
       <HomeIconSlider/>
       <AllContactUs/>
    </div>
  )
}

export default About;
