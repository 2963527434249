import React, { useState } from 'react';
import "./ProjectsDetails.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ProjectsDetails = ({ location, cost, description, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className='ProjectsDetails-Container'>
      <div className='ProjectsDetails-SectionLeft'>
        <div className='ProjectsDetails-ImageSliderLeft'>
          <button onClick={handlePrevImage} className='ProjectsDetails-NavButton'>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <img src={images[currentImageIndex].image} alt='' className='ProjectsDetails-Images' />
          <button onClick={handleNextImage} className='ProjectsDetails-NavButton'>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div className='ProjectsDetails-DotsContainer'>
          {images.map((_, index) => (
            <span
              key={index}
              className={`ProjectsDetails-Dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>

      <div className='ProjectsDetails-SectionRight'>
        <h1 className='ProjectsDetails-Location'>Location:</h1>
        <p className='ProjectsDetails-locationAnswer'>{location}</p>
        <h1 className='ProjectsDetails-Cost'>Cost:</h1>
        <p className='ProjectsDetails-locationAnswer'>{cost}</p>
        <h1 className='ProjectsDetails-Description'>Description:</h1>
        <p className='ProjectsDetails-locationAnswer'>{description}</p>
      </div>
    </div>
  );
}

export default ProjectsDetails;
