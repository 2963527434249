import React from "react";
import "./AllIntroduction.css";
import AllLinkPages from "../../component/AllLinkPages/AllLinkPages";

const AllIntroduction = ({title, description, image}) => {
  return (
    <div>
      <div className="allIntroduction">
        <div className="allIntroduction__left">
          <h1 className="allIntroduction__heading">{title}</h1>
          <p className="allIntroduction__description">{description}</p>
        </div>
        <div className="allIntroduction__right">
          <img src={image} alt="AboutUs-photo3" />
        </div>
      </div>
      <div className="allIntroduction__emptyContainer">
        <AllLinkPages/>
      </div>
    </div>
  );
};

export default AllIntroduction;
