import React from 'react';
import './Home.css';
import TotalData from "../../jsonData/TotalData.json";
import HeroIntro from '../../component/HeroIntro/HeroIntro';
import AllTestimonial from '../../component/AllTestimonial/AllTestimonial';
import AllProjects from '../../component/AllProjects/AllProjects';
import OurPresence from '../../component/OurPresence/OurPresence';
import AllContactUs from '../../component/AllContactUs/AllContactUs';

const Home = () => {
  
  const data = TotalData[3];

  return (
    <div className='Home-container'>
      <HeroIntro data={data}/>
    <AllTestimonial/>
    <AllProjects />
    <OurPresence data={data}/>
    <AllContactUs/>
    </div>
  )
}

export default Home;
