import React from 'react';
import "./AllCapabilities.css";
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const CapabilitiesGrid = ({ Items, page }) => {
  return (
    <div className="capabilitiesGrid-GridContainer">
      {Items.map((capability) => (
        <Link to={`/${page}/${capability.id}`} key={capability.id} className="capabilitiesGrid-GridItem">
            <div className="capabilitiesGrid-ContentImage">
              <img
                className="capabilitiesGrid-GridImage"
                src={capability.image}
                alt={capability.title}
              />
              <h1 className="capabilitiesGrid-GridTitlemain">{capability.title}</h1>
              <p className="capabilitiesGrid-GridDescriptionMain">{capability.description}</p>
            </div>
        </Link>
      ))}
    </div>
  );
};

const AllCapabilities = ({ title, description, capabilities, CurrentPage }) => {
  return (
    <div className='AllCapabilities-MainContainer'>
      <div className='AllCapabilities-CapTitleStyle'>{title}</div>
      <div className='AllCapabilities-CapDescriptionStyle'>{description}</div>
      <div className='AllCapabilities-CapabilitiesGrid'>
        <CapabilitiesGrid Items={capabilities} page={CurrentPage} />
      </div>
    </div>
  );
}

export default AllCapabilities;


