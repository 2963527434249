import React, { useEffect } from 'react';
import './AllContactUs.css'; // Import the CSS file
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from '../Button/Button';
import TotalData from "../../jsonData/TotalData.json";

function AllContactUs() {
  // Define animations
  const animationControls = useAnimation();
  const [ref, inView] = useInView();
   const data = TotalData[0];

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  return (
    <motion.div
      className="contact-container"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={animationControls}
    >
      <div className='contact-leftside'>
        <h1 className="contact-title">{data.ContactHeader}</h1>
      </div>
      <div className='contact-rightside'>
        <p className="contact-description">{data.ContactDescription}</p>
        <Button to={data.ContactLink} icon={faUser}>Contact Us</Button>
      </div>
    </motion.div>
  );
}

export default AllContactUs;
